import React, { useState } from "react"
import Img from "gatsby-image"
import tw, { styled } from "twin.macro"
import Layout from "../components/layout"
import { Link } from "gatsby"
// import { CartItems } from "../components/cart"
// import { FiShoppingCart } from "react-icons/fi"
import {
  HeaderWrap,
  SubHeader,
  Header,
  ProductWrap,
  ProductCard,
} from "../components/productListStyles"
import Footer from "../components/Footer"
import LogRocket from "logrocket"
import { CouponBanner } from "."
LogRocket.init("ziiqiw/studio-conscious")

const CartButton = styled.div`
  position: fixed;
  top: 50px;
  right: 15px;
  @media (min-width: 1024px) {
    top: 15px;
    right: 30px;
  }
  z-index: 6000;
  button {
    &:hover {
      cursor: pointer;
    }
    background: none;
    border: none;
    &:focus {
      outline: none;
    }
  }
`

const HeaderGroup = styled.div`
  font-family: "Playfair Display";
  font-weight: 600;
  font-size: 42px;
  text-align: center;
  text-transform: lowercase;
  @media (max-width: 1024px) {
    font-size: 26px;
  }
`
const HeaderGroupCapital = styled.div`
  font-family: "Playfair Display";
  font-weight: 600;
  font-size: 42px;
  text-align: center;
  @media (max-width: 1024px) {
    font-size: 26px;
  }
`

const SoonCard = styled.div`
  height: 550px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${({ background }) => background});
  display: grid;
  align-items: center;
  justify-items: center;
  h3 {
    margin: 0;
    a {
      font-size: 40px;
      font-family: Playfair Display;
      color: ${({ colorSelect }) => colorSelect};
    }
  }
`

const SoonWrapper = styled.div`
  ${tw`grid grid-cols-1 col-gap-16 sm:grid-cols-3 mt-8 mb-16`}
`

const Goods = ({ data, location }) => {
  const [close, setClose] = useState(false)

  // const toggleCart = () => {
  //   setClose(!close)
  // }
  // const showCart = () => {
  //   setClose(true)
  // }

  return (
    <Layout location={location}>
      {/* <CartItems close={close} location={location} />
      <CartButton>
        <button onClick={toggleCart}>
          <FiShoppingCart size={25} />
        </button>
      </CartButton> */}
      {/* <CouponBanner>
        <h2>25% off all products </h2>
        <h3>automatically applied at checkout</h3>
      </CouponBanner> */}
      <HeaderWrap>
        {data.sanityGoods &&
          data.sanityGoods.header.map(head => {
            return <Header color={head.myColor.hex}>{head.title}</Header>
          })}
      </HeaderWrap>
      <SubHeader>{data.sanityGoods && data.sanityGoods.subHeader}</SubHeader>
      <HeaderGroupCapital>available at Re:Plenish</HeaderGroupCapital>

      <ProductWrap>
        {data.replenish.edges.map(({ node: product }, i) => {
          return (
            <Link
              key={i}
              to={`/goods/${product.name.replace(/\s/g, "-").toLowerCase()}/${
                product.price_id
              }`}
            >
              <ProductCard>
                <Img
                  fluid={
                    product.featureImage && product.featureImage.asset.fluid
                  }
                />
                <h3>{product.name}</h3>
              </ProductCard>
            </Link>
          )
        })}
      </ProductWrap>
      <HeaderGroup>upcycled goods</HeaderGroup>

      <ProductWrap>
        {data.upcycledGoods.edges.map(({ node: product }, i) => {
          return (
            <Link
              key={i}
              to={`/goods/${product.name.replace(/\s/g, "-").toLowerCase()}/${
                product.price_id
              }`}
            >
              <ProductCard>
                <Img
                  fluid={
                    product.featureImage && product.featureImage.asset.fluid
                  }
                />
                <h3>{product.name}</h3>
              </ProductCard>
            </Link>
          )
        })}
      </ProductWrap>
      <HeaderGroup>conscious finds</HeaderGroup>

      <ProductWrap>
        {data.consciousFinds.edges.map(({ node: product }, i) => {
          return (
            <Link
              key={i}
              to={`/goods/${product.name.replace(/\s/g, "-").toLowerCase()}/${
                product.price_id
              }`}
            >
              <ProductCard>
                <Img
                  fluid={
                    product.featureImage && product.featureImage.asset.fluid
                  }
                />
                <h3>{product.name}</h3>
              </ProductCard>
            </Link>
          )
        })}
      </ProductWrap>
      <HeaderGroup>rare finds</HeaderGroup>

      <ProductWrap>
        {data.rareFinds.edges.map(({ node: product }, i) => {
          return (
            <Link
              key={i}
              to={`/goods/${product.name.replace(/\s/g, "-").toLowerCase()}/${
                product.price_id
              }`}
            >
              <ProductCard>
                <Img
                  fluid={
                    product.featureImage && product.featureImage.asset.fluid
                  }
                />
                <h3>{product.name}</h3>
              </ProductCard>
            </Link>
          )
        })}
      </ProductWrap>
      <HeaderWrap>
        <Header
          className="spin"
          color={data?.sanityGoods?.comingSoonColor?.hex}
        >
          {data.sanityGoods.comingSoonHeader}
        </Header>
      </HeaderWrap>
      <SoonWrapper>
        {data.allSanityComingSoon.edges.map(({ node: soon }, i) => {
          return (
            <SoonCard
              colorSelect={soon.titleColor.hex}
              background={soon.background.asset.url}
            >
              <h3>
                <a href={soon.link}>{soon.title}</a>
              </h3>
            </SoonCard>
          )
        })}
      </SoonWrapper>
      <Footer />
    </Layout>
  )
}

export default Goods

export const query = graphql`
  query ProductsGoodsQuery {
    upcycledGoods: allSanityProducts(
      filter: {
        archived: { eq: false }
        page: { eq: "goods" }
        categories: { title: { eq: "upcycled goods" } }
      }
    ) {
      edges {
        node {
          price_id
          product_id
          price
          name
          featureImage {
            asset {
              fluid(maxWidth: 700) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
    consciousFinds: allSanityProducts(
      filter: {
        archived: { eq: false }
        page: { eq: "goods" }
        categories: { title: { eq: "conscious finds" } }
      }
    ) {
      edges {
        node {
          price_id
          product_id
          price
          name
          featureImage {
            asset {
              fluid(maxWidth: 700) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
    replenish: allSanityProducts(
      filter: {
        archived: { eq: false }
        page: { eq: "goods" }
        categories: { title: { eq: "Available at Re:Plenish" } }
      }
    ) {
      edges {
        node {
          price_id
          product_id
          price
          name
          featureImage {
            asset {
              fluid(maxWidth: 700) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
    rareFinds: allSanityProducts(
      filter: {
        archived: { eq: false }
        page: { eq: "goods" }
        categories: { title: { eq: "rare finds" } }
      }
    ) {
      edges {
        node {
          price_id
          product_id
          price
          name
          featureImage {
            asset {
              fluid(maxWidth: 700) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
    sanityGoods {
      header {
        title
        myColor {
          hex
        }
      }
      subHeader
    }
    sanityGoods {
      comingSoonHeader
      comingSoonColor {
        hex
      }
      header {
        title
        myColor {
          hex
        }
      }
      subHeader
    }
    allSanityComingSoon(filter: { page: { eq: "goods" } }) {
      edges {
        node {
          background {
            asset {
              url
            }
          }
          title
          link
          titleColor {
            hex
          }
        }
      }
    }
  }
`
